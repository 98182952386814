$(document).ready(function () {
	//Hide element when smaller than 1025
	if ($(window).width() < 1025) {
		$(".header-hotline").fadeIn(function () {
			$(this).appendTo("main");
			$(".header-hotline").css({
				display: "flex",
			});
		});
		$(".header-social-network ").fadeIn(function () {
			$(this).appendTo("main");
			$(".header-social-network ").css({
				display: "flex",
			});
		});
	}

	setBackground();
	tabActive();
	swiperInit();
	toggleFunc();
	backToTop();
	iframeRatio();
	appendCommentNews();
	
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});
function backToTop() {
	let btn = $(".back-to-top");
	btn.on("click", function (e) {
		e.preventDefault();
		$("html, body").animate(
			{
				scrollTop: 0,
			},
			"300"
		);
	});
}

function iframeRatio() {
	if ($("body").hasClass("news-detail-page")) {
		if ($(".news-detail .fullcontent iframe").length) {
			$(".news-detail .fullcontent iframe").wrap(
				"<div class='iframe-ratio'></div>"
			);
		}
	}
}
function toggleFunc() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$(".mobile-navbar-primary-menu").toggleClass("menu-toggle-mobile");
		$hamburger.toggleClass("is-active");
	});
	$(".read-more-wrap").each(function () {
		if ($(this).find(".txt-wrap").outerHeight() < 220) {
			$(this).find(".btn-read-more").remove();
		} else {
			$(this).find(".txt-wrap").css({
				height: "220",
			});
		}

		let button = $(this).find(".btn-read-more");
		let content = $(this);
		button.on("click", function () {
			content.toggleClass("active");
		});
	});
	$(".mobile-navbar-primary-menu .title em").on("click", function () {
		$(this).parent().next().slideToggle();
	});

	$(".side-navigation-nav .title em").on("click", function () {
		if ($(this).parents("li").hasClass("active")) {
			$(".side-navigation-nav .drop-down").slideUp();
			$(".side-nav li").removeClass("active");
		} else {
			$(".side-navigation-nav .drop-down").slideUp();
			$(".side-nav li").removeClass("active");
			$(this).parent().next().slideDown();
			$(this).parents("li").addClass("active");
		}
	});
	$(".support-toggle-wrapper").each(function (index, element) {
		let $this = $(this);
		$this.addClass("toggle-instance-" + index);
		$(".toggle-instance-" + index + " .toggle-item").click(function () {
			if ($(this).hasClass("is-toggle")) {
				console.log("istoggle");
				$(this).find(".article").slideUp();
				$(this).removeClass("is-toggle");
			} else {
				$(this).find(".article").slideDown();
				$(this).addClass("is-toggle");
				console.log("no");
			}
		});
	});

	if ($(window).width() < 1025) {
		$(".side-navigation-nav .text ").on("click", function () {
			$(this).next().slideToggle();
		});
	}
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	var homeNewsThumb = new Swiper(".home-news-thumb  .swiper", {
		spaceBetween: 20,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
				direction: "horizontal",
			},
			450: {
				slidesPerView: 1,
				direction: "horizontal",
			},
			768: {
				slidesPerView: 2,
				direction: "horizontal",
				spaceBetween: 5,
			},
			1024.1: {
				slidesPerView: 4,
				direction: "vertical",
			},
			1440: {
				slidesPerView: 4,
				direction: "vertical",
			},
		
		},
		mousewheel: {
		  },
		pagination: {
			el: ".home-news-thumb  .swiper-pagination",
			type: "progressbar",
		},
		slideToClickedSlide: true,
		navigation: {
			nextEl: ".project-swiper-thumb .nav-next",
			prevEl: ".project-swiper-thumb .nav-prev",
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var homeNewsMain = new Swiper(".home-news-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		spaceBetween: 20,
		lazy: {
			loadPrevNext: true,
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: homeNewsThumb,
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	
	});


	if ($(window).width() > 1025) {
		$(".home-news-thumb .swiper-slide").on("mouseenter", function () {
			homeNewsMain.slideTo($(this).index());
			homeNewsMain.autoplay.start()
		});
	}

	var partnerSwiper = new Swiper(".partner-site-swiper  .swiper", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 30,
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			200: {
				slidesPerView: 2,
				spaceBetween: 10,
			},
			450: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			769: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
			1440: {
				slidesPerView: 6,
			},
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});
	var topBanner = new Swiper(".top-banner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
					spaceBetween: 10,
				},
				450: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
	$(".double-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-quad" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-quad" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
}

//Tab active

function tabActive() {
	$(".tab-navigation li a").on("click", function () {
		$(this).parents(".tab-navigation").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}
// Side
function setBackground() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center",
		});
	});
	$("[setBackgroundRepeat]").each(function () {
		var background = $(this).attr("setBackgroundRepeat");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-repeat": "repeat",
		});
	});
}

// $(document).ready(function () {
// 	jQuery(function ($) {
// 		"use strict";

// 		var counterUp = window.counterUp["default"]; // import counterUp from "counterup2"

// 		var $counters = $(".counter");

// 		/* Start counting, do this on DOM ready or with Waypoints. */
// 		$counters.each(function (ignore, counter) {
// 			var waypoint = new Waypoint({
// 				element: $(this),
// 				handler: function () {
// 					counterUp(counter, {
// 						duration: 2000,
// 						delay: 2,
// 					});
// 					this.destroy();
// 				},
// 				offset: "bottom-in-view",
// 			});
// 		});
// 	});
// });

function appendCommentNews(){
	$('.product-comment').appendTo( ".news-detail .container" );
}